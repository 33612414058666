@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,700);
/* hides the errors */

.App {
  text-align: center;
  font-size: 20px;
  padding-left: calc(100vw - 100%);
}

/* shorthand */
.flex { display: flex;}
.relative { position: relative;}
.absolute { position: absolute;}

.column { flex-direction: column;}
.row { flex-direction: row;}
.rowWhenLarge { flex-direction: column; }
@media (min-width:1000px) { .rowWhenLarge { flex-direction: row; } }
.wrap { flex-wrap: wrap;}
.justify-content-center { justify-content: center;}
.justify-content-between { justify-content: space-between;}
.justify-content-end { justify-content: flex-end;}
.align-items-center { align-items: center;}
.align-items-end { align-items: flex-end;}
.flexCenter { display: flex; justify-content: center; align-items: center;}
.block { display: block; }
.overflow-hidden { overflow: hidden;}
.rounded-corners { border-radius: 5px; }

.text-align-center, .tac { text-align: center;}
.text-align-right, .tar { text-align: right;}
.text-align-left, .tal { text-align: left;}
.italic {font-style: italic;}
.font-size-30 { font-size: 30px;}
.font-size-20 { font-size: 20px;}

.largeFont { font-size: 28px; }
.mediumFont { font-size: 16px; }
.smallFont { font-size: 8px; }

.vh100 { height: 100vh; }
.h100 { height: 100px;}
.w100p { width: 100%;}
.w50 { width: 50px;}
.w50p { width: 50%;}
.w50p-when-large{ width: 100%; }
@media (min-width:1000px) { .w50p-when-large { width: 50%; }}
.w100 { width: 100px;}
.h100 { height: 100px;}
.w200 { width: 200px;}
.h200 { height: 200px;}
.w300 { width: 300px;}
.h300 { height: 300px;}
.w700 { width: 700px;}
.maxw700 { max-width: 700px;}

.m0 { margin: 0 !important; }
.mb5 { margin-bottom: 5px;}
.mb10 { margin-bottom: 10px;}
.mb20 { margin-bottom: 20px;}

.mauto { margin: auto;}
.mr5 { margin-right: 5px;}
.mr10 { margin-right: 10px;}
.mr20 { margin-right: 20px;}
.mb20 { margin-bottom: 20px;}
.mb40 { margin-bottom: 40px;}
.ml5 { margin-left: 5px;}
.ml10 { margin-left: 10px;}
.ml20 { margin-left: 20px;}

.mt5 { margin-top: 5px;}
.mt10 { margin-top: 10px;}
.mr10 { margin-right: 10px;}
.mt20 { margin-top: 20px;}

.p10 { padding: 10px;}
.p20 { padding: 20px;}
.p30 { padding: 30px;}

.background-gray { background-color: gray;}
.background-lightgray { background-color: lightgray;}
.background-white { background-color: white;}
.border-radius { border-radius: 3px; }

.outline { outline: 1px solid blue;}


.btn-outline-primary {
  color: #0093ff;
  cursor: pointer;
  border-color: #0093ff;
}

.btn-outline-primary:hover {
  background-color: #0093ff;
  border-color: #0093ff;
}
.full-screen-center {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif!important;
  font-weight: 300!important;
  line-height: 1.5!important;
  letter-spacing: 0.05em!important;
  font-size: 15px!important;
}

h1{
  font-size: 40px;
}

a:not(.btn) {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.underline { text-decoration: underline; }

.section{
  background-color: white;
  max-width: 40em;
  margin: 0 auto;
}

.page-buffer { padding: 15px; }
.jumbotron{ text-align: center; }
.jumbotron-desc { font-size: 12px; }
li{ display: inherit;} /* removes bullet */ 
@media (min-width: 400px) {
  .jumbotron-desc { font-size: inherit; }
}

/* Timeline styling */

/* Styling */
.timeline {
  margin: 4em auto;
  position: relative;
  max-width: 46em;
  padding: 0;
}
.timeline:before {
  background-color: #ddd;
  content: '';
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 2em;
  width: 2px;
  height: 100%;
}

.timeline-event {
  position: relative;
}
.timeline-event:hover .timeline-event-icon, .active .timeline-event-icon {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #ff5531;
}
.timeline-event:hover .timeline-event-thumbnail, .active .timeline-event-thumbnail {
  box-shadow: inset 40em 0 0 0 #ff5531;
  color: white;
}

.timeline-event-list {
  padding-left: 17px;
}

.timeline-event-copy {
  padding: 2em 2em 0em;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 85%;
}

@media (min-width: 850px) {
  .timeline-event-copy {
    width: 95%;
  }
}

.timeline-event-copy h3 {
  font-size: 1.75em;
}
.timeline-event-copy h4 {
  font-size: 1.2em;
  margin-bottom: 1.2em;
}
.timeline-event-copy strong {
  font-weight: 700;
}
.timeline-event-copy p:not(.timeline-event-thumbnail) {
  padding-bottom: 1.2em;
}

.timeline-event-icon {
  transition: -webkit-transform 0.1s ease-in;
  transition: transform 0.1s ease-in;
  transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
  background-color:#ddd;
  outline: 20px solid white;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em;
  z-index: 1;
}

.timeline-event-thumbnail {
  transition: box-shadow .3s ease-in;
  color: #666;
  font-size: 0.75em;
  background-color: #ddd;
  box-shadow: inset 0 0 0 0em #fff600;
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
}

.App {
  text-align: center;
}


body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

