/* hides the errors */

.App {
  text-align: center;
  font-size: 20px;
  padding-left: calc(100vw - 100%);
}

/* shorthand */
.flex { display: flex;}
.relative { position: relative;}
.absolute { position: absolute;}

.column { flex-direction: column;}
.row { flex-direction: row;}
.rowWhenLarge { flex-direction: column; }
@media (min-width:1000px) { .rowWhenLarge { flex-direction: row; } }
.wrap { flex-wrap: wrap;}
.justify-content-center { justify-content: center;}
.justify-content-between { justify-content: space-between;}
.justify-content-end { justify-content: flex-end;}
.align-items-center { align-items: center;}
.align-items-end { align-items: flex-end;}
.flexCenter { display: flex; justify-content: center; align-items: center;}
.block { display: block; }
.overflow-hidden { overflow: hidden;}
.rounded-corners { border-radius: 5px; }

.text-align-center, .tac { text-align: center;}
.text-align-right, .tar { text-align: right;}
.text-align-left, .tal { text-align: left;}
.italic {font-style: italic;}
.font-size-30 { font-size: 30px;}
.font-size-20 { font-size: 20px;}

.largeFont { font-size: 28px; }
.mediumFont { font-size: 16px; }
.smallFont { font-size: 8px; }

.vh100 { height: 100vh; }
.h100 { height: 100px;}
.w100p { width: 100%;}
.w50 { width: 50px;}
.w50p { width: 50%;}
.w50p-when-large{ width: 100%; }
@media (min-width:1000px) { .w50p-when-large { width: 50%; }}
.w100 { width: 100px;}
.h100 { height: 100px;}
.w200 { width: 200px;}
.h200 { height: 200px;}
.w300 { width: 300px;}
.h300 { height: 300px;}
.w700 { width: 700px;}
.maxw700 { max-width: 700px;}

.m0 { margin: 0 !important; }
.mb5 { margin-bottom: 5px;}
.mb10 { margin-bottom: 10px;}
.mb20 { margin-bottom: 20px;}

.mauto { margin: auto;}
.mr5 { margin-right: 5px;}
.mr10 { margin-right: 10px;}
.mr20 { margin-right: 20px;}
.mb20 { margin-bottom: 20px;}
.mb40 { margin-bottom: 40px;}
.ml5 { margin-left: 5px;}
.ml10 { margin-left: 10px;}
.ml20 { margin-left: 20px;}

.mt5 { margin-top: 5px;}
.mt10 { margin-top: 10px;}
.mr10 { margin-right: 10px;}
.mt20 { margin-top: 20px;}

.p10 { padding: 10px;}
.p20 { padding: 20px;}
.p30 { padding: 30px;}

.background-gray { background-color: gray;}
.background-lightgray { background-color: lightgray;}
.background-white { background-color: white;}
.border-radius { border-radius: 3px; }

.outline { outline: 1px solid blue;}

